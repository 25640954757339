<template>
<div id="stationCenter">
  <!--头部搜索-->
  <div class="bg-white searchDom">
    <div class="flex align-center justify-end margin-bottom-24 header_search">
      <div class="flex align-center solid margin-right-24 padding-lr-16" style="height:32px;width: 300px;">
        <el-input @input="changeData" v-model="sendData.keyword" placeholder="请输入站长ID查询" suffix-icon="search"></el-input>
        <i style="cursor: pointer;"  class="el-icon-search" @click="changeData"></i>
      </div>
      <el-button @click="addStation('ruleForm')" style="height: 32px;width: 68px;padding: 0;color: #FFFFFF;background-color: #4E9F5B;">添加站长</el-button>
      <el-button @click="exportData" style="height: 32px;width: 68px;padding: 0;color: #4E9F5B !important;border-color: #4E9F5B;">导出</el-button>
    </div>
  </div>
  <!--搜索列表-->
  <div class="searchList">
    <ul class="service-list row align-center margin-bottom-26 flex-start">
      <li class="service-list-item margin-bottom-16"
        v-for="(v,i) in dataList" :key="i">
          <div class="service-title">
              <h3>{{v.zz_name}}</h3>
          </div>
          <div class="service-content">
              <ul class="service-content-list">
                  <li class="flex">
                      <h4>站长ID：</h4>
                      <p>{{v.zz_id}}</p>
                  </li>
                  <li class="flex">
                      <h4>时间：</h4>
                      <p>{{v.zz_create_time*1000|getDate}}</p>
                  </li>
                  <li class="flex">
                      <h4>站长头衔：</h4>
                      <p>{{v.zz_title}}</p>
                  </li>
                  <li class="flex">
                      <h4>联系电话：</h4>
                      <p>{{v.zz_contact}}</p>
                  </li>
                  <li class="flex">
                      <h4>地区：</h4>
                      <p>{{v.zz_area}}</p>
                  </li>
                  <li class="flex">
                      <div class="p1">
                        <h4 class="flex">大学：<p>{{v.zz_school}}</p></h4>
                      </div>
                      <div class="p1">
                        <h4 class="flex">已提：<p>{{v.zz_income_money}}</p></h4>
                      </div>
                  </li>
                  <li class="flex">
                      <div class="p1">
                        <h4 class="flex justify-between">发展用户数：
                          <p>{{v.zz_user_reg_count}}</p>
                          <el-button v-if="v.zz_user_reg_count!='0'" @click="showModel(v.zz_id,'发展用户数')" type="text" size="medium" style="color: #4E9F5B;margin-top:-15px;margin-left:10px">详情</el-button>
                        </h4>
                      </div>
                      <div class="p1">
                         <h4 class="flex">站长等级：<p>{{v.zz_level}}</p></h4>
                      </div>
                  </li>
                  <li class="flex">
                      <div class="p1">
                        <h4 class="flex">站长收益比：<p>{{v.zz_rate_alias}}</p></h4>
                      </div>
                      <div class="p1">
                        <h4 class="flex">预计分佣：<p>{{v.ready_zz_all_money}}</p></h4>
                      </div>
                  </li>
              </ul>
                <!-- <a href="javascript:;" target="_blank" class="service-list-more">了解更多</a> -->
              <div class="ope flex align-center justify-center" style="margin-top:-10px">
                  <el-button @click="add_Xj(v.zz_id)" style="color: #4E9F5B !important;" class="btn-primary mt-2 mr-2 toastr_top_left_btn" type="text" size="medium">添加下线</el-button>
                  <el-button v-if="v.zz_state=='1'" @click="stopAcc(v.zz_id,0)" style="color: #4E9F5B !important;" class="btn-primary mt-2 mr-2 toastr_top_left_btn" type="text" size="medium">冻结账号</el-button>
                  <el-button v-if="v.zz_state=='0'" @click="stopAcc(v.zz_id,1)" style="color: #4E9F5B !important;" class="btn-primary mt-2 mr-2 toastr_top_left_btn" type="text" size="medium">冻结</el-button>
                  <el-button @click="restPassword(v.zz_id)" style="color: #4E9F5B !important;" class="btn-primary mt-2 mr-2 toastr_top_left_btn" type="text" size="medium">重置密码</el-button>
              </div>
        </div>
      </li>
    </ul>
  </div>
  <!--分页-->
  <div class="pageNum flex align-center justify-center" id="pageNum">
    <Pagination
      v-show="rows>1"
      :total="rows"
      @change = "getStation"
      :page.sync= "sendData.page"
      :limit.sync="sendData.limit"/>
  </div>
  <!-- 添加站长后台账号-->
  <el-dialog id="addStation" @close='closeStation' title="添加站长后台账号" :visible.sync="dialogVisible"
    :close-on-press-escape='false' :close-on-click-modal='false'>
    <el-form id="form" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px" class="demo-ruleForm">
      <el-form-item label="姓名" prop="zz_name">
        <el-input v-model="ruleForm.zz_name" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="zz_contact">
        <el-input v-model="ruleForm.zz_contact" placeholder="请输入手机号码"></el-input>
      </el-form-item>
      <el-form-item label="地区" prop="zz_area">
        <el-input v-model="ruleForm.zz_area" placeholder="请输入地区"></el-input>
      </el-form-item>
      <el-form-item label="大学" prop="zz_school">
        <el-input v-model="ruleForm.zz_school" placeholder="请输入大学名称"></el-input>
      </el-form-item>
      <el-form-item label="站长头衔" prop="zz_title">
        <el-input v-model="ruleForm.zz_title" placeholder="请输入站长头衔"></el-input>
      </el-form-item>
      <el-form-item label="后台账号" prop="zz_acc">
        <el-input v-model="ruleForm.zz_acc" placeholder="请输入账号"></el-input>
      </el-form-item>
      <el-form-item label="后台密码" prop="zz_pwd">
        <el-input v-model="ruleForm.zz_pwd" placeholder="请输入密码" show-password></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="submitStationForm('ruleForm')">提 交</el-button>
    </span>
  </el-dialog>  
  <!--添加下线弹框 -->
  <el-dialog id="zz_add_xj" :visible.sync="zz_addXj">
    <div class="flex align-center justify-between">
      <span style="color: #000000;font-size: 16px;">添加下线</span>
      <i @click="close_zzAdd" class="el-icon-close" style="color: #C8C9CC;font-size: 20px;cursor: pointer;"></i>
    </div>
    <div class=""
      style="height: 48px;background: #FFFFFF;border-radius: 2px;border: 1px solid #EBEDF0;box-sizing: border-box;padding: 0 10px;margin-top: 44px;">
      <input style="width: 100%;height: 100%;" type="number" placeholder="请输入下线ID" v-model="zz_userId" />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button style="width: 74px;height: 32px;background: #4E9F5B;border-radius: 2px;padding: 0;" type="primary"
        @click="add_zz_Xj">添 加</el-button>
    </span>
  </el-dialog>
  <!-- 重置密码弹框 -->
  <el-dialog id="resPasswordModel" @close='closeResPassword' title="重置密码" :visible.sync="showResetBox"
    :close-on-press-escape='false' :close-on-click-modal='false'>
    <div class="padding-16 text-14 text-333 margin-top-24 radius-4 flex align-center justify-center" style="height:100px">
      <span class="solid-bottom" style="font-size:24px;font-weight:700;display:inline-block;width:100%;padding-bottom:10px;text-align: center;">{{resetInfo}}</span>
    </div>
    <div class="flex align-center justify-center">
        <el-button @click="changePassword" style="width: 260px;height: 40px;background: #4E9F5B;border-radius: 4px;color:#fff">修 改</el-button>
    </div>
  </el-dialog>

  <!-- 发展用户数表格数据 -->
    <el-dialog title="发展用户数" :close-on-press-escape='false' :close-on-click-modal='false' id="allTabModel" 
    :visible.sync="showTabModel" @close='closeTabModel'>
    <!-- 内容 -->
    <div class="margin-tb-24">
      <el-table :header-cell-style="{background:'#F5F6F7',color:'#333333',fontsize:'14px'}" :data="tableList.list"
        stripe height="450" style="width: 100%;">
        <el-table-column type="index" align="center" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="user_nickname" align="center" label="微信昵称" width="180">
        </el-table-column>
        <el-table-column prop="user_create_time" align="center" label="注册时间" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.user_create_time*1000 | getDate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="user_jk1_all_count" align="center" label="TA的下级">
            <template slot-scope="scope">
              <span>{{scope.row.user_jk1_all_count+scope.row.user_jk2_all_count}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="order_count" align="center" label="下单数">
        </el-table-column>
        <el-table-column prop="ready_ma_all_money" align="center" label="预估收益">
            <template slot-scope="scope">
              <span>{{scope.row.ready_ma_all_money+scope.row.ready_jk1_all_money+scope.row.ready_jk2_all_money}}</span>
            </template>          
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <el-pagination
          v-show="tableList.count>1"
			    :current-page="tableList.page"
			    :page-sizes="[10,20,30]"
			    :page-size="tableList.limit"
			    layout="total, sizes, prev, pager, next, jumper"
			    :total="tableList.count"
			    @size-change="handleSizeChange"
			    @current-change="handleCurrentChange">
			  </el-pagination>
  </el-dialog>
</div>
</template>
<script>
import {stationList,stationAdd,stationEdit,stationUser,stationUserBind} from '@/api/http'
import Pagination from '@/components/pagination'
export default {
    data(){
      return{
        zzid: '',
        zz_userId: '',
        dataList: [], //表格数据
        /* 分页参数*/
        pages:1, //总页数
        rows:1, //总条数
        /* 弹窗参数*/
        showTabModel: false,
        modelTitle: '',
        modelUserId: 1,
        modelTabInfo: {},
        zz_addXj:false,
        //发送数据
        sendData:{
          keyword:'',
          page:1,
          limit:10
        },
        currentUserId:'',
        //发展用户数表格数据
        tableList:{},
        //重置密码
        resetInfo:'',
        showResetBox:false,//重置密码弹框
        zzId:'',
        /* 添加站长弹窗*/
        dialogVisible: false,
        ruleForm: {
          zz_name: '',
          zz_title: '',
          zz_area: '',
          zz_school: '',
          zz_contact: ''
        },
        rules: {
          zz_acc: [{
            required: true,
            message: '请输入账号',
            trigger: 'blur'
          }],
          zz_pwd: [{
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }],
          zz_name: [{
            required: true,
            message: '请输入姓名',
            trigger: 'blur'
          }],
          zz_area: [{
            required: true,
            message: '请输入地区',
            trigger: 'blur'
          }],
          zz_school: [{
            required: true,
            message: '请输入学校',
            trigger: 'blur'
          }],
          zz_contact: [{
            required: true,
            message: '请输入联系方式',
            trigger: 'blur'
          }],
          zz_title: [{
            required: true,
            message: '请输入站长头衔',
            trigger: 'blur'
          }]

        },
      }
    },
    created(){
      if(localStorage.getItem('now_station_info') && this.$route.query.sqzz == 1){
        this.ruleForm.zz_name = JSON.parse(localStorage.getItem(now_station_info)).user_nickname || ''
        this.ruleForm.zz_contact = JSON.parse(localStorage.getItem(now_station_info)).feedback_contact || ''
        this.ruleForm.zz_area = JSON.parse(localStorage.getItem(now_station_info)).feedback_area || ''
        this.ruleForm.zz_school = JSON.parse(localStorage.getItem(now_station_info)).feedback_school || ''
        this.dialogVisible = true
      }
      this.getStation()
    },
    methods:{
      //获取数据列表
      getStation(){
        stationList(this.sendData).then(res=>{
          console.log('站长',res.data)
          let {list,page,count,limit}=res.data.data
          console.log(list,page,count,limit)
          if(res.data.code == '1' && list){
            this.rows = count
            this.dataList = list
          }
        })
      },
      add_Xj(zzid){
        console.log(zzid)
        this.zzid = zzid
        this.zz_addXj = true
      },
      add_zz_Xj(){
			if(this.zz_userId==''){
				this.$message.error('请输入下级ID')
				return false
			}
			console.log({zz_id:this.zzid,user_id:this.zz_userId})

			stationUserBind({zz_id:this.zzid,user_id:this.zz_userId}).then(res=>{
				if(res.data.code=='1'){
					this.$message.success('添加成功')
				}else{
					this.$message.error(res.info)
				}
			})
		},
      stopAcc(id,num){
        let that = this
        let str = "是否确认 冻结 此账号?"
        if(num==1){
          str='是否确认 解冻 此账号?'
        }
        this.$confirm(str, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          stationEdit({zz_id:id,zz_state:num}).then(res=>{
            console.log('冻结账号',res)
            if(res.data.code=='1'){
              that.$message.success('操作成功')
              that.getStation()
            }else{
              that.$message.error(res.info)
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
      },
      //打开弹窗
      showModel(id,title){
        console.log(id,title)
        //获取站长推广列表
        this.getStationUser(id,1,10)
        this.currentUserId = id
        this.showTabModel = true
      },
      //获取站长推广人列表
      getStationUser(id,page,limit){
        stationUser({
          zz_id:id,
          page:page,
          limit:limit
        }).then(res=>{
          console.log('推广人数',res.data)
          let {list,page,count,limit}=res.data.data
          console.log(list,page,count,limit)
          if(res.data.code == '1' && list){
            this.rows = count
            this.tableList = res.data.data
          }
        })
      },
      //添加站长信息
      addStation(formName){
        this.ruleForm.zz_name = ''
        this.ruleForm.zz_name = ''
        this.ruleForm.zz_title = ''
        this.ruleForm.zz_area = ''
        this.ruleForm.zz_school = ''
        this.ruleForm.zz_contact = ''
        this.dialogVisible = true
      },
      closeStation(){
        this.dialogVisible = false
      },
      close_zzAdd() {
        this.zz_addXj = false
        this.zz_userId = ''
        this.zzid = ''
      },
      //添加站长
      submitStationForm(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log(this.ruleForm)

            this.sendStationRequest(formName)
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      sendStationRequest(formName){
        stationAdd(this.ruleForm).then(res=>{
          console.log('添加站长',res.data)
          if(res.data.code == '1'){
            this.$message.success('添加成功')
            this.$refs[formName].resetFields()
            this.getStation()
            this.dialogVisible = false
          }else{
            this.$message.error(res.info)
          }
        })
      },
      changeData() {
        this.sendData.page == '1'
        this.getStation()
      },
      emptyKeyword(e) {
        if (e.length == '0') {
          this.changeData()
        }
      },
      //导出
      exportData(){
        this.sendData.limit = 9999
        this.getStation()
      },
      //重置密码
      restPassword(id){
        let randnum = (Math.random() * 99999999).toFixed(0)
        console.log(randnum)
        this.resetInfo = randnum
        this.zzId = id
        this.showResetBox = true
      },
      closeResPassword(){
        this.showResetBox = false
      },
      changePassword(){
        stationEdit({zz_id:this.zzid,zz_pwd:this.resetInfo}).then(res=>{
          console.log('重置密码',res)
        })
      },
      closeTabModel(){
        this.showTabModel = false
      },
      handleSizeChange(pageSize) {
        console.log('组件', pageSize)
        this.getStationUser(this.currentUserId,this.tableList.page,pageSize)
      },
      handleCurrentChange(currentPage) {
        console.log(currentPage)
        this.getStationUser(this.currentUserId,currentPage,this.tableList.limit)
      },
    },
    components:{
      Pagination
    }
}
</script>
<style>
  #stationCenter .header_search .el-input__inner {
    height: 20px !important;
    border: none !important;
    padding: 0 !important;
  }

  /* 添加站长弹窗---开始*/
  #stationCenter #addStation .el-dialog {
    width: 440px;
    border-radius: 4px;
  }

  #stationCenter #addStation .el-dialog__header {
    text-align: left;
  }

  #stationCenter #addStation .el-dialog__header .el-dialog__title {
    font-size: 16px;
    color: #000000;
    font-weight: bold;
  }

  #stationCenter #addStation .el-dialog__body {
    padding: 24px;
  }

  #stationCenter #addStation .el-dialog__footer {
    text-align: center !important;
  }

  #stationCenter #addStation .el-dialog__footer button {
    width: 260px;
    height: 40px;
    background: #4E9F5B;
    border-radius: 4px;
    color: white;
  }

  #stationCenter #addStation #form .el-input__inner {
    border: none !important;
  }

  #stationCenter #addStation #form .el-form-item {
    border-bottom: 1px solid #F5F6F7 !important;
  }

  #stationCenter #addStation #form .el-form-item__label {
    text-align: left !important;
  }

  /* 添加站长弹窗---结束*/

  /* 添加下级 */
  #stationCenter #zz_add_xj .el-dialog {
    width: 400px;
    box-shadow: 0px 2px 34px 0px rgba(122, 122, 129, 0.21);
    border-radius: 8px;
    padding: 16px;
  }

  #stationCenter #zz_add_xj .el-dialog .el-dialog__header {
    display: none !important;
    padding: 0 !important;
  }

  #stationCenter #zz_add_xj .el-dialog .el-dialog__body {
    height: 300px;
    padding: 0 !important;
  }

  #stationCenter #zz_add_xj .el-dialog .el-dialog__footer {
    padding: 0 !important;
  }

  #stationCenter #zz_add_xj input::-webkit-outer-spin-button,
  #stationCenter #zz_add_xj input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  #stationCenter #zz_add_xj input[type='number'] {
    -moz-appearance: textfield;
  }   
  #resPasswordModel{
    width: 640px;
    border-radius: 8px;
    padding: 24px !important;
    margin: 0 auto;
  }
.service-list{
  padding: 16px;
}
.service-list .service-list-item {
    float: left;
    width: 19%;
    margin-right: 16px;
    background: #fff;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    transition: all .2s;
}
.service-title{
  background-color: #0f2c14 !important;
  height: 45px;
}
.service-title h3 {
    font-size: 24px;
    text-align: center;
    color: #fff;
    line-height: 45px;
    font-weight: normal;
}

.service-content {
    border: 1px solid #E7E9EC;
    border-top: 0;
}

.service-content-list {
    margin-top: 20px;
}

.service-content-list li {
    position: relative;
    text-align: left;
}
.service-content-list li .p1{
  margin-right: 10px;
}
.service-content-list li h4 p{
  font-weight: normal;
}
.service-content-list li:before {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #C1C5CB;
    content: "";
    position: absolute;
    left: -16px;
    top: 7px;
}

.service-content-list li h4 {
    font-size: 14px;
    color: #616366;
    font-weight: 700;
    margin-bottom: 3px;
    line-height: 20px;
}

.service-content-list li p {
    font-size: 14px;
    color: #333;
}

.service-list-more {
    width: 200px;
    height: 40px;
    display: block;
    margin: 0 auto;
    color: #F96A69;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    border: 1px solid #F96A69;
    transition: all .2s;
}

.service-list-more:hover {
    background: #F96A69;
    color: #fff;
}

.service-list .service-list-item:hover {
    box-shadow: 0 7px 39px rgba(0,0,0,.1)
}
#allTableModel .el-dialog {
    border-radius: 8px;
    width: 780px !important;
    height: 600px !important;
    /* overflow-y: scroll; */
  }


  #allTableModel .el-dialog__header {
    display: none !important;
  }

  #allTableModel .el-dialog__body {
    padding: 24px !important;
  }
</style>